export type FontsTypes = typeof fonts;

export const fonts = {
  $xxs: "12px",
  $xs: "14px",
  $sm: "16px",
  $base: "18px",
  $lg: "20px",
  $xl: "22px",
  $xxl: "24px",
};
